import { useEffect, useState } from 'react'

export default function useMedia(queries, values, defaultValue) {
  const [value, setValue] = useState(defaultValue)

  useEffect(
    () => {
      const mediaQueryLists = queries.map((q) => window && window.matchMedia(q))
      const getValue = () => {
        const index = mediaQueryLists.findIndex((mql) => mql.matches)
        return typeof values[index] !== 'undefined' ? values[index] : defaultValue
      }
      const handler = () => setValue(getValue)
      mediaQueryLists.forEach((mql) => mql.addListener(handler))
      setTimeout(() => setValue(getValue), 750)
      return () => mediaQueryLists.forEach((mql) => mql.removeListener(handler))
    },
    []
  )

  return value
}
