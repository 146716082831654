import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import Link from 'next/link'
import { servicesBySearch } from '../../queries/services'
import { useDebounce } from '../../utils/hooks/debounce'
import useMedia from '../../utils/hooks/media'

export default function HeaderSearch({ show, close }) {
  const [state, setState] = useState({
    displayed: false,
    term: '',
  })
  const [search, { data }] = useLazyQuery(servicesBySearch)
  const debouncedSearchTerm = useDebounce(state.term, 500)

  const dp = {
    container: {
      position: 'absolute',
      left: '-220px',
      width: '220px',
      top: '-13px',
    },
    results: {
      top: '60px',
      width: '300px',
      left: '-40px'
    }
  }

  const mb = {
    container: {
      position: 'absolute',
      bottom: '-80px',
      left: '0',
      right: '0',
      background: 'white',
      padding: '1rem',
    },
    results: {
      top: '80px',
      width: 'calc(100% - 32px)',
      left: '16px'
    }
  }

  const sm = {
    container: {
      position: 'absolute',
      bottom: '-80px',
      left: '-24px',
      right: '0',
      background: 'white',
      padding: '1rem',
    },
    results: {
      top: '80px',
      width: 'calc(100% - 32px)',
      left: '16px'
    }
  }

  const style = useMedia(
    ['(min-width: 1024px)', '(min-width: 768px)', '(min-width: 0px)'],
    [
      dp,
      mb,
      sm,
    ],
    sm
  )

  useEffect(() => {
    if (show && !state.displayed) {
      document.body.style.overflow = 'hidden'
      setState(s => ({ ...s, displayed: true }))
    } else if (!show && state.displayed) {
      document.body.style.overflow = 'auto'
      setState(s => ({ ...s, displayed: false }))
    }
  }, [show, state.displayed])

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length > 0) {
      window.gtag && window.gtag('event', 'search', {
        search_term: debouncedSearchTerm
      })
      search({
        variables: {
          search: `%${debouncedSearchTerm}%`,
        },
      })
    }
  }, [debouncedSearchTerm])

  // const handle = (service) => {
  //   setState({ ...state, term: '' })
  //   close()
  // }

  return (
    <div className={`flex flex-col transition-all ${state.displayed ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}`} style={style.container}>
      <label className="block w-full">
        <input
          type="search"
          name="search"
          value={state.term}
          placeholder="Search for service"
          className="appearance-none relative block w-full px-4 py-4 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:shadow-outline-brand focus:z-10 sm:text-sm sm:leading-5 focus:outline-none focus:ring-brand-500 focus:border-brand-500 focus:placeholder-gray-400"
          onChange={(e) => setState({ ...state, term: e.target.value })}
        />
      </label>
      {data && data.content && (
        <div className='p-3 bg-white absolute border border-gray-300 rounded-md shadow-md' style={style.results}>
          {data.content.map(item => (
            <div key={item.id} className='py-2'>
              <Link href={item.metadata.path} passHref className='block font-serif'>
                {item.title}
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
